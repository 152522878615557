
        @import 'styles/mixins.scss';
    
.container {
    max-width: 615px;
    margin: 0 auto;
    input {
        outline: 2px solid #5793ec;
        &::placeholder {
            @apply  text-lg md:text-xl  ;
        }
    }
}
.searchResult {
    @apply py-6;
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
   
    color: var(--color);
    a {
        color: unset;
    }
    p {
        em {
            font-weight: bold;
            font-style: normal;
            color: #5793ec;
        }
    }
    h3 {
        color: black;
        em {
            font-style: normal;
        }
    }
    &:hover {
        background-color: #5793ec39 !important;
    } 
}

.searchResultOverview {
    color: black;
    @include line-clamp(2)
}

.searchResultTitle {
    em {
        color: #5793ec;
    }
}

.anchor {
    &:hover {
        text-decoration: none;
    }
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        inset:0;
    }
}