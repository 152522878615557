
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.footer {
    border-top: 2px solid #f2f2f2;
}
.social {
    border: 1px solid #e9e9e9;
    &:hover {
        border-color: #ccc;
    }
}
.ftrLnk {
    color: $ftrLinkColor;
}
.ftrTitle {
    color: #000;
}
.ftrLnkWrapper {
    & > div:nth-child(2) {
        @media screen and (min-width: 1048px) {
            margin-left: 50px;
            margin-right: 50px;
        }
    }
}
