
        @import 'styles/mixins.scss';
    
.linkButtonStyle {
    color: #fff !important;
    direction: ltr;
    font-size: 14px;
    @include sm {
        font-size: 16px;
    }
}
.callLinkStyle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #fff;
    background-color: #0e7fd4;
}
.linkButtonStyleWithIcon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
