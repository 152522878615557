$error: #ff4242;
$linkColor: #5793ec;
$ftrLinkColor: #777777;
$defaultButtonBgColor: #1693f0;
$defaultButtonTextColor: #fff;

$defaultButtonDisabledBgColor: #84c5f7;
$defaultButtonDisabledTextColor: #fff;

$disabledSelectTextColor: #797c80;

$defaultBorderColor: $defaultButtonBgColor;
$defaultArrowColor: #000;

$insurance-super-saver-blue-aster: #266199;
$insurance-super-saver-assault: #184070;
$insurance-super-saver-lucea: #79b4e2;
$insurance-super-saver-distant-horizon: #f4f9fa;
