
        @import 'styles/mixins.scss';
    
.input {
    background-color: white;
    // border-bottom: 1px solid #fff;
    &:focus-visible {
        outline: none;
        // border: 3px #1693f0 solid;
        // border-bottom: 1px solid #1693f0;
    }

    font-size: medium;
    min-height: 50px;
    @apply text-[#58616d] text-xl md:text-3xl  p-8 ps-12 md:ps-16;
    &::placeholder {
        @apply text-[#58616d] text-xl md:text-3xl ps-12 md:ps-16;
    }
}

.inputContainer {
    color: #58616d;
    position: relative;
    svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 20px;
        height: 20px;
        @include md {
            width: 30px;
            height: 30px;
        }
    }
}

.loading {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 25px;
        right: 10px;
        transform: translate(-50%, -50%);
        border: 2px solid #1693f0;
        border-radius: 50%;
        border-top: 2px solid transparent;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.searchResults {
    max-height: calc(100vh - 200px);

    @include md {
        padding-right: 15px;
        margin-right: 15px;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */

    scrollbar-width: auto !important;
    scrollbar-color: #f8bb20 transparent !important;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f8bb20 !important;
        border-radius: 0px;
        border: 0px solid #ffffff;
    }
}
