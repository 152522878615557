
        @import 'styles/mixins.scss';
    
.header {
    background: #0e2450;
    color: #fff;
}
.active {
    @media screen and (min-width: 1049px) {
        border-bottom: 2px solid #f9bb21;
        text-align: center;
        color: #f9bb21;
        padding-inline: 3px;
    }
}
.severalLogo {
    width: 175px;
    height: 45px;
    @media screen and (max-width: 1048px) {
        width: 96px;
        height: 26px;
    }
    svg {
        width: 100%;
        height: auto;
    }
}
.menu {
    @media screen and (max-width: 1048px) {
        // display: none;
        position: fixed;
        right: 0;
        height: 100vh;
        width: 100%;
        background: rgba(14, 36, 80, 0.97);
        z-index: 100;
        top: 0;
        transform: translate3d(1048px, 0, 0);
        transition: all 0.3s;
    }

    &.open {
        transform: translate3d(0, 0, 0);
        // display: block;
    }
}

.burger {
    width: 26px;
    height: 18px;
    // position: fixed;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 101;
    display: none;
    right: 30px;

    @media screen and (max-width: 1048px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.burger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    display: block;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.burger span:nth-child(1) {
    top: 0px;
}

.burger span:nth-child(2) {
    top: 8px;
}

.burger span:nth-child(3) {
    top: 16px;
}

.burger.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.burger.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}


.link {
    @include underline;
}

.search {
    position: relative;
    &:hover {
        svg {
            path {
                fill: #f9bb21 !important;
            }
        }
    }
    @include underline;
}

.searchButton {
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 10;
    }
}
.languageSwitcherVisible {
    display: none;
    @include lg {
        display: block;
    }
}
