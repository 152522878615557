
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";

.modalStyle {
    position: fixed;
    width: 100%;
    background-color: rgba(12, 23, 39, 0.98);
    height: 100vh;
    top: 0px;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    animation: fadeIn 0.2s ease-in-out;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.contentStyle {
    // background-color: white;
    // border-radius: 8px;
    // padding: 0.5rem 1rem;
    text-align: left;
    height: 100%;
}

.closeIcon {
    background-color: white;
    border-radius: 100px 0 0 100px;
    width: 67px;
    height: 75px;
    display: none;
    @include lg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    padding-left: 10px;
    cursor: pointer;
    // border: 1px solid;
}
.hoverStyle {
    margin-right: 2px;
    transition: all 0.1s ease-in-out;
    padding: 8px;
}
.hoverStyle:hover {
    path {
        fill: #f9bb21;
        stroke: #f9bb21;
        transition: all 0.1s ease-in-out;
    }
}
