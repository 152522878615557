
        @import 'styles/mixins.scss';
    
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
        border-radius: 16px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #cacaca !important;
        border-radius: 16px !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b5b5b5 !important;
    }
}

.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}

.embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 10px;
}

.embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
}

.embla__dot:after {
    background-color: #efefef;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    content: "";
}

.embla__dot.is-selected:after {
    background-color: #14244f;
    opacity: 1;
}
// END AMBLA STYLES

:root {
    scrollbar-color: #f1f1f1 !important;
    scrollbar-width: thin !important;
}

a {
    color: #5793ec;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
html {
    // scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

html[lang="ar"] body {
    direction: rtl;
    font-family: "Almarai", sans-serif;
}
html[lang="en"] body {
    font-family: "Mulish", sans-serif;
}

.lightBg {
    background-color: #f6f8fb;
}
.pac-logo:after {
    background-image: none !important;
    width: 0 !important;
    height: 0 !important;
}
/******************************** vendor page style start*********************************/

.offerContainer {
    p {
        margin-top: 40px;
        font-size: 14px;
        line-height: 32px;

        @include lg {
            font-size: 18px;
        }
        strong {
            a {
                font-size: 20px;
                line-height: 30px;
                color: black;
                @include lg {
                    font-size: 30px;
                }
            }
            :hover {
                text-decoration: none;
            }
        }
        :hover {
            text-decoration: none;
        }
    }
    P:nth-child(1) strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        @include lg {
            font-size: 30px;
        }
    }
    P:nth-child(2) {
        margin-top: 15px;
    }

    div {
        margin-top: 40px;
    }
    ul {
        text-align: left;
    }
}

/******************************** vendor page style end *********************************/
/******************************** post page style start ***********************************/

.postContainer {
    margin-top: 30px;

    p {
        margin-top: 40px;
        font-size: 14px;
        line-height: 26px;
        color: #333;

        @include lg {
            font-size: 18px;
            line-height: 26px;
        }
    }
    ul {
        text-align: left;
    }
}
.newsContainer {
    margin-top: 30px;

    p {
        margin-top: 40px;
        font-size: 14px;
        line-height: 26px;
        color: #333;

        @include lg {
            font-size: 18px;
            line-height: 26px;
        }
    }
    ul {
        text-align: left;
    }
}
/******************************** post page style end***********************************/
/********* shared styles between vendor page and post page start *************/
.postContainer,
.offerContainer {
    img {
        margin-top: 24px;
    }
    ol {
        margin-top: 40px;
        margin-left: 25px;
        @include lg {
            margin-left: 35px;
        }
        list-style: decimal;
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
        }
    }
    li {
        ol,
        ul {
            margin-top: 5px !important;
            @include lg {
                margin-top: 10px !important;
            }
            margin-left: 15px !important;
        }
    }
    ul {
        // margin-top: 40px;
        margin-left: 30px;
        list-style: disc;
        @include lg {
            margin-left: 45px;
        }
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
            ul {
                margin-top: 10px;
                margin-left: 20px;
                list-style-type: circle;
            }
        }
    }
    p + ul,
    p + ol {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
        color: #1693f0 !important;

        padding: 5px 10px;
        position: relative;
        color: #1693f0;
        background-color: #f2f8ff;
        & > br {
            display: none;
        }
        span,
        strong,
        a {
            color: #1693f0;
        }

        @include lg {
            font-size: 30px;
            line-height: 38px;
            padding: 12px 20px;
        }
        a {
            color: black;
            :hover {
                text-decoration: none;
            }
        }
        :hover {
            text-decoration: none;
        }
    }
    h3 {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 40px;

        @include lg {
            font-size: 25px;
        }
        a {
            font-size: 15px;
            font-weight: 700;
            margin-top: 0;
            @include lg {
                font-size: 25px;
                color: #000;
            }
        }
    }
    h4 {
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 30px;

        @include lg {
            font-size: 22px;
        }
    }
    h2 + p,
    h2 + ul {
        margin-top: 10px;
        @include lg {
            margin-top: 15px;
        }
    }
    h3 + p,
    h3 + ul,
    h4 + p,
    h4 + ul {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }
    img + p {
        margin-top: -15px;
    }

    table {
        overflow-x: auto;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 400px;
        border: 0 #c8d0df;
        border-collapse: collapse;
        margin-top: 20px;
    }
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }
    tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
        max-width: 100%;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    table tr th {
        color: #fff;
        border-color: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(176, 176, 176, 0.5);

        background: #072c60;
        padding: 15px;
        font-size: 0.875rem;
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
        p {
            margin: 0;
            color: #fff;
        }
    }
    table tr td {
        padding: 15px;
        border: 1px solid rgba(176, 176, 176, 0.5);
        font-size: 0.875rem;
        display: table-cell;
        text-align: center;
        vertical-align: inherit;
        min-width: 200px;
        @include lg {
            min-width: auto;
        }
        p {
            margin: 0;
        }
    }

    // div h2:only-child {
    //     margin-bottom: -30px;
    // }
}

.newsContainer {
    img {
        margin-top: 24px;
    }
    ol {
        margin-top: 40px;
        margin-left: 25px;
        @include lg {
            margin-left: 35px;
        }
        list-style: decimal;
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
        }
    }
    li {
        ol,
        ul {
            margin-top: 5px !important;
            @include lg {
                margin-top: 10px !important;
            }
            margin-left: 15px !important;
        }
    }
    ul {
        // margin-top: 40px;
        margin-left: 30px;
        list-style: disc;
        @include lg {
            margin-left: 45px;
        }
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
            ul {
                margin-top: 10px;
                margin-left: 20px;
                list-style-type: circle;
            }
        }
    }
    p + ul,
    p + ol {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
        color: black !important;
        font-weight: 700;
        padding: 5px 10px;
        position: relative;
        & > br {
            display: none;
        }
        span,
        strong,
        a {
            color: #1693f0;
        }

        @include lg {
            font-size: 30px;
            line-height: 38px;
            padding: 12px 0;
        }
        a {
            color: black;
            :hover {
                text-decoration: none;
            }
        }
        :hover {
            text-decoration: none;
        }
    }
    h3 {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 40px;

        @include lg {
            font-size: 25px;
        }
        a {
            font-size: 15px;
            font-weight: 700;
            margin-top: 0;
            @include lg {
                font-size: 25px;
                color: #000;
            }
        }
    }
    h4 {
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 30px;

        @include lg {
            font-size: 22px;
        }
    }
    h2 + p,
    h2 + ul {
        margin-top: 10px;
        @include lg {
            margin-top: 15px;
        }
    }
    h3 + p,
    h3 + ul,
    h4 + p,
    h4 + ul {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }
    img + p {
        margin-top: -15px;
    }

    table {
        overflow-x: auto;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 400px;
        border: 0 #c8d0df;
        border-collapse: collapse;
        margin-top: 20px;
    }
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }
    tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
        max-width: 100%;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    table tr th {
        color: #fff;
        border-color: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(176, 176, 176, 0.5);

        background: #072c60;
        padding: 15px;
        font-size: 0.875rem;
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
        p {
            margin: 0;
            color: #fff;
        }
    }
    table tr td {
        padding: 15px;
        border: 1px solid rgba(176, 176, 176, 0.5);
        font-size: 0.875rem;
        display: table-cell;
        text-align: center;
        vertical-align: inherit;
        min-width: 200px;
        @include lg {
            min-width: auto;
        }
        p {
            margin: 0;
        }
    }

    // div h2:only-child {
    //     margin-bottom: -30px;
    // }
}

body[dir="rtl"] {
    .offerContainer {
        ul {
            text-align: right;
        }
    }
    .postContainer {
        ul {
            text-align: right;
        }

        // p {
        //     margin: 0;
        // }
    }
    .newsContainer {
        ul {
            text-align: right;
        }

        // p {
        //     margin: 0;
        // }
    }

    @media screen and (min-width: 1024px) {
        .postContainer ul,
        .offerContainer ul,
        .newsContainer {
            margin-right: 45px;
        }
    }

    .postContainer,
    .offerContainer {
        img {
            margin-top: 24px;
        }
        ol {
            margin-top: 40px;
            margin-right: 25px;
            @include lg {
                margin-right: 35px;
            }
            list-style: decimal;
            li {
                font-size: 14px;
                line-height: 32px;

                @include lg {
                    font-size: 18px;
                }
            }
        }
        li {
            ol,
            ul {
                margin-top: 5px !important;
                @include lg {
                    margin-top: 10px !important;
                }
                margin-right: 15px !important;
            }
        }
        ul {
            // margin-top: 40px;
            margin-right: 30px;
            list-style: disc;
            @include lg {
                margin-right: 45px;
            }
            li {
                font-size: 14px;
                line-height: 32px;

                @include lg {
                    font-size: 18px;
                }
                strong {
                    color: #777777;
                }
                b {
                    color: #777777;
                }
                ul {
                    margin-top: 10px;
                    margin-right: 20px;
                    list-style-type: circle;
                }
            }
        }
        p + ul,
        p + ol {
            margin-top: 5px;
            @include lg {
                margin-top: 10px;
            }
        }

        h2 {
            font-size: 20px;
            line-height: 28px;
            margin-top: 40px;
            color: #1693f0 !important;

            padding: 5px 10px;
            position: relative;
            color: #1693f0;
            background-color: #f2f8ff;
            & > br {
                display: none;
            }
            span,
            strong,
            a {
                color: #1693f0;
            }

            @include lg {
                font-size: 30px;
                line-height: 38px;
                padding: 12px 20px;
            }
            a {
                color: black;
                :hover {
                    text-decoration: none;
                }
            }
            :hover {
                text-decoration: none;
            }
        }
        h3 {
            font-size: 15px;
            font-weight: 700;
            line-height: 30px;
            margin-top: 40px;

            @include lg {
                font-size: 25px;
            }
            a {
                font-size: 15px;
                font-weight: 700;
                margin-top: 0;
                @include lg {
                    font-size: 25px;
                    color: #000;
                }
            }
        }
        h4 {
            font-size: 17px;
            font-weight: 600;
            line-height: 30px;
            margin-top: 30px;

            @include lg {
                font-size: 22px;
            }
        }
        h2 + p,
        h2 + ul {
            margin-top: 10px;
            @include lg {
                margin-top: 15px;
            }
        }
        h3 + p,
        h3 + ul,
        h4 + p,
        h4 + ul {
            margin-top: 5px;
            @include lg {
                margin-top: 10px;
            }
        }
        img + p {
            margin-top: -15px;
        }

        table {
            overflow-x: auto;
            width: 100% !important;
            max-width: 100% !important;
            min-width: 400px;
            border: 0 #c8d0df;
            border-collapse: collapse;
            margin-top: 20px;
        }
        thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
        }
        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
            max-width: 100%;
        }
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
        }
        table tr th {
            color: #fff;
            border-color: rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(176, 176, 176, 0.5);

            background: #072c60;
            padding: 15px;
            font-size: 0.875rem;
            display: table-cell;
            vertical-align: inherit;
            font-weight: bold;
            p {
                margin: 0;
                color: #fff;
            }
        }
        table tr td {
            padding: 15px;
            border: 1px solid rgba(176, 176, 176, 0.5);
            font-size: 0.875rem;
            display: table-cell;
            text-align: center;
            vertical-align: inherit;
            min-width: 200px;
            @include lg {
                min-width: auto;
            }
            p {
                margin: 0;
            }
        }

        // div h2:only-child {
        //     margin-bottom: -30px;
        // }
    }

    .newsContainer {
        img {
            margin-top: 24px;
        }
        ol {
            margin-top: 40px;
            margin-right: 25px;
            @include lg {
                margin-right: 35px;
            }
            list-style: decimal;
            li {
                font-size: 14px;
                line-height: 32px;

                @include lg {
                    font-size: 18px;
                }
            }
        }
        li {
            ol,
            ul {
                margin-top: 5px !important;
                @include lg {
                    margin-top: 10px !important;
                }
                margin-right: 15px !important;
            }
        }
        ul {
            // margin-top: 40px;
            margin-right: 30px;
            list-style: disc;
            @include lg {
                margin-right: 45px;
            }
            li {
                font-size: 14px;
                line-height: 32px;

                @include lg {
                    font-size: 18px;
                }
                strong {
                    color: #777777;
                }
                b {
                    color: #777777;
                }
                ul {
                    margin-top: 10px;
                    margin-right: 20px;
                    list-style-type: circle;
                }
            }
        }
        p + ul,
        p + ol {
            margin-top: 5px;
            @include lg {
                margin-top: 10px;
            }
        }

        h2 {
            font-size: 20px;
            line-height: 28px;
            margin-top: 40px;
            color: black !important;

            padding: 5px 0;
            position: relative;
            & > br {
                display: none;
            }
            span,
            strong,
            a {
                color: #1693f0;
            }

            @include lg {
                font-size: 30px;
                line-height: 38px;
                padding: 12px 20px;
            }
            a {
                color: black;
                :hover {
                    text-decoration: none;
                }
            }
            :hover {
                text-decoration: none;
            }
        }
        h3 {
            font-size: 15px;
            font-weight: 700;
            line-height: 30px;
            margin-top: 40px;

            @include lg {
                font-size: 25px;
            }
            a {
                font-size: 15px;
                font-weight: 700;
                margin-top: 0;
                @include lg {
                    font-size: 25px;
                    color: #000;
                }
            }
        }
        h4 {
            font-size: 17px;
            font-weight: 600;
            line-height: 30px;
            margin-top: 30px;

            @include lg {
                font-size: 22px;
            }
        }
        h2 + p,
        h2 + ul {
            margin-top: 10px;
            @include lg {
                margin-top: 15px;
            }
        }
        h3 + p,
        h3 + ul,
        h4 + p,
        h4 + ul {
            margin-top: 5px;
            @include lg {
                margin-top: 10px;
            }
        }
        img + p {
            margin-top: -15px;
        }

        table {
            overflow-x: auto;
            width: 100% !important;
            max-width: 100% !important;
            min-width: 400px;
            border: 0 #c8d0df;
            border-collapse: collapse;
            margin-top: 20px;
        }
        thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
        }
        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
            max-width: 100%;
        }
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
        }
        table tr th {
            color: #fff;
            border-color: rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(176, 176, 176, 0.5);

            background: #072c60;
            padding: 15px;
            font-size: 0.875rem;
            display: table-cell;
            vertical-align: inherit;
            font-weight: bold;
            p {
                margin: 0;
                color: #fff;
            }
        }
        table tr td {
            padding: 15px;
            border: 1px solid rgba(176, 176, 176, 0.5);
            font-size: 0.875rem;
            display: table-cell;
            text-align: center;
            vertical-align: inherit;
            min-width: 200px;
            @include lg {
                min-width: auto;
            }
            p {
                margin: 0;
            }
        }

        // div h2:only-child {
        //     margin-bottom: -30px;
        // }
    }
}

.media.media--type-remote-video,
.media.media--type-video {
    width: 100%;
}
.align-center {
    text-align: center;
}
.media {
    display: flex;

    align-items: flex-start;
}
.media--type-video-embed,
.media--type-remote-video,
.media--type-video {
    position: relative;
}
.align-center {
    margin-right: auto;
    margin-left: auto;
}
.embed-responsive {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 0;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.litebox,
.blazy iframe,
.media iframe {
    display: block;
    max-width: 100%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}
.embed-responsive::before {
    display: block;
    content: "";
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.box.yellow-box {
    background: #fff9e5;
}
.box {
    position: relative;
}
.blue-box-bg,
.box,
.green-box-bg,
.red-box-bg,
.yellow-box-bg {
    margin-bottom: 1rem;
    padding: 25px;
}
.box.yellow-box:before {
    border-top: 30px solid #f9d55d;
}
.box:before {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-right: 30px solid transparent;
}
.box:before,
.image-hover:before {
    top: 0;
    left: 0;
    content: "";
}
.box.blue-box {
    background: #edf6ff;
}
.box {
    position: relative;
}
.blue-box-bg,
.box,
.green-box-bg,
.red-box-bg,
.yellow-box-bg {
    margin-bottom: 1rem;
    padding: 25px;
}
.box.blue-box:before {
    border-top: 30px solid #35a9ff;
}
.box:before {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-right: 30px solid transparent;
}
.box:before,
.image-hover:before {
    top: 0;
    left: 0;
    content: "";
}
.yellow-box-bg {
    background: #fff9e5;
}
.blue-box-bg,
.box,
.green-box-bg,
.red-box-bg,
.yellow-box-bg {
    margin-bottom: 1rem;
    padding: 25px;
    margin-top: 10px;
}
.btn {
    background-color: #1693f0;
    font-weight: 500;
    color: white !important;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    display: block;
    min-width: 300px;
    text-align: center;
    :hover {
        text-decoration: none;
    }
}
.btn:hover {
    background-color: #68beff;
    transition: all 0.1s ease-in-out;
}
a.btn {
    text-decoration: none;
}
.table-responsive {
    max-width: calc(100vw - 2rem);
    width: auto;
    display: block;
    overflow-x: auto;
    // margin-top: 15px !important;
}
.iframe-responsive {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
/********* shared styles between vendor page and post page end *************/

/* override datepicker style */
.rmdp-header-values > span {
    border: 1px solid #1693f0 !important;
    padding: 5px 15px !important;
    margin-left: 5px !important;
    border-radius: 5px !important;
}
.rmdp-header-values > span:hover {
    background-color: #1693f0 !important;
    color: white;
}

.rmdp-day.rmdp-today > .sd {
    background-color: transparent !important;
    border: 1px solid rgb(249, 187, 33) !important;
    color: black !important;
}
figcaption {
    padding: 4px 8px;
    background-color: #fffcf1;
    color: #6b7280;
    font-size: 14px;
}
.rmdp-year-picker {
    .rmdp-day.rmdp-today > span {
        background-color: transparent !important;
        border: 1px solid rgb(249, 187, 33) !important;
        color: black !important;
    }
    .rmdp-day.rmdp-selected > span {
        background-color: #1693f0 !important;
        color: white !important;
        border: none !important;
    }
}

.rmdp-month-picker {
    .rmdp-day.rmdp-today > span {
        background-color: transparent !important;
        border: 1px solid rgb(249, 187, 33) !important;
        color: black !important;
    }
    .rmdp-day.rmdp-selected > span {
        background-color: #1693f0 !important;
        color: white !important;
        border: none !important;
    }
}

.rmdp-day > span {
    &:hover {
        background-color: #1693f0 !important;
    }
}

.rmdp-day.rmdp-selected > .sd {
    background-color: #1693f0 !important;
    color: white !important;
    border: none !important;
}

.summary {
    h2 {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin: 10px 0 15px 0;
        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }
}
.tspa-tooltip {
    display: inline-block;
    cursor: pointer;
    color: #1693f0;
    border-bottom: 1px solid #1693f0;
    position: relative;
    span {
        display: none;
        position: absolute;
        background-color: black;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        min-width: 150px;
        transform: translateX(-50%) translateY(-100%);
        left: 50%;
        top: -5px;
        line-height: 1.4;
        font-size: 9px;
    }
    &:hover {
        &:before,
        &:after,
        span {
            display: block;
        }
    }
    &:after {
        width: 9px;
        height: 9px;
        background-color: black;
        display: none;
        transform: translateX(-50%) translateY(-23px) rotate(45deg);
        left: 50%;
        content: "";
        position: absolute;
    }
}

.top-buyer-brief-text-block-container {
    h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 800;

        @media screen and (max-width: 766px) {
            font-size: 20px;
        }
    }

    p {
        font-size: 20px;
        margin-top: 0.75rem;
        color: rgb(88, 97, 109);

        @media screen and (max-width: 766px) {
            font-size: 14px;
            margin-top: 0;
        }
    }
}

.tcpa-Modal {
    display: inline-block;
    cursor: pointer;
    color: blue;
    border-bottom: 1px solid blue;
    > span {
        display: none;
    }
}
