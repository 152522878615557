
        @import 'styles/mixins.scss';
    
.circle-loader {
    border: 2px solid var(--bg-color);
    border-left-color: #1693f0;
    animation: loader-spin 1s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 7em;
    height: 7em;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    transition: border 1000ms ease;
}

.checkmark.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg) translate(-50%, -50%);
}

.checkmark {
    color: #1693f0;
}
.checkmark:after {
    opacity: 1;
    height: 53%;
    width: 26%;
    transform-origin: left top;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    left: 50%;
    top: 44%;
    position: absolute;
    transform: translate(-50%, -50%);
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 53%;
        width: 26%;
        opacity: 1;
    }
    40% {
        height: 53%;
        width: 26%;
        opacity: 1;
    }
    100% {
        height: 53%;
        width: 26%;
        opacity: 1;
    }
}
