
        @import 'styles/mixins.scss';
    
.buttonColor {
    background-color: #1693f0;
    color: #fff;
}
.linkColor {
    color: #5793ec;
}
.anyThing {
    max-width: 350px;
}
.paragraphColor {
    color: #58616d;
}
.borderStyle {
    border: 1px solid #d0d0d0;
}
.borderStyleTop {
    border: 4px solid #1693f0;
}

.orderStyle:hover {
    box-shadow: 0px 0px 9px 3px rgb(0 0 0 / 15%);
    transition: 0.3s ease-in-out;
}

.orderStyle > div:nth-of-type(1) {
    order: 1;
}
.orderStyle > div:nth-of-type(2) {
    order: 3;
}
.orderStyle > div:nth-of-type(3) {
    order: 2;
}

@include lg {
    .orderStyle > div:nth-of-type(1) {
        order: 1;
    }
    .orderStyle > div:nth-of-type(2) {
        order: 2;
    }
    .orderStyle > div:nth-of-type(3) {
        order: 3;
    }
}

.checkBoxStyle {
    position: relative;

    input {
        visibility: hidden;
        user-select: none;
        position: absolute;
        left: -100rem;
        &:checked + label .box svg path {
            stroke-dashoffset: 0;
        }
        &:checked + label .box {
            background-color: white;
        }
    }
    label {
        display: flex;
        align-items: center;
        min-height: 46px;
        .box {
            display: block;
            border: 1px solid black;
            width: 15px;
            height: 15px;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.2s ease;
            padding: 2px;

            svg {
                pointer-events: none;
                path {
                    fill: none;
                    stroke: white;
                    stroke-width: 6px;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-dasharray: 100;
                    stroke-dashoffset: 101;
                    transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                }
            }
        }
    }
}

.selectStyle {
    box-shadow: 0 0 0px 3px #f9bb21;
}
.listImage img {
    width: 100% !important;
    height: auto !important;
    min-width: inherit !important;
    max-width: inherit !important;
    min-height: inherit !important;
    max-height: inherit !important;
}
.topBuyerImage {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    @include lg {
        height: 80px;
        width: 140px;
    }
    & > span {
        width: 100% !important;
        height: 100% !important;
        img {
            width: auto !important;
            height: auto !important;
            min-height: 100% !important;
            min-width: auto !important;
            position: absolute !important;
        }
        & > span {
            display: none !important;
        }
    }
}
