$xs: 340px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

@mixin xs {
    @media (max-width: #{$xs}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$sm}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$md}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$lg}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$xl}) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: #{$xxl}) {
        @content;
    }
}

@mixin custom-radio($color) {
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: "";
        min-width: 18px;
        min-height: 18px;
        border-radius: 100%;
        background: linear-gradient(180deg, #e8e8e8 0%, #f7f7f7 100%);
        border: 1px solid $color;
        box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
        margin-right: 15px;
    }
    [type="radio"]:checked + label:before {
        content: "";
        width: 16px;
        height: 16px;
        background: $color;
        border: 3px solid #efefef;
        outline: 1px solid $color;
        margin-left: 1px;
    }
}

@mixin line-clamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin glow-effect {
    &:not([disabled]) {
        position: relative;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        &::before {
            content: "";
            background: rgba(white, 0.4);
            width: 60%;
            height: 100%;
            top: 0%;
            left: -125%;
            transform: skew(30deg);
            position: absolute;
            transition: left 0.4s ease-in-out;
        }
        &:hover {
            &::before {
                left: 125%;
            }
        }
        [dir="rtl"] & {
            &::before {
                left: 125%;
            }
            &:hover {
                &::before {
                    left: -125%;
                }
            }
        }
    }
}

@mixin underline($color: #f9bb21, $height: 2px, $border-radius: 99px) {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: $height;
        border-radius: $border-radius;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        background: $color;
    }
    &:hover {
        &::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }

    [dir="rtl"] & {
        &::before {
            transform-origin: bottom left;
        }
        &:hover {
            &::before {
                transform-origin: bottom right;
            }
        }
    }
}
